import styles from "./ApprovalButton.module.css";
import OfferButton from "../components/OfferButton";
import { useState } from "react";
import ApprovalDialog from "./ApprovalDialog";
import { OfferApprovalDetails } from "@violet/common";
import { reportEvent } from "../util/AnalyticUtils";

interface OfferProps {
  approved: boolean;
  offerId: string;
  onApproved: (approvalDetails: OfferApprovalDetails) => Promise<any>;
}

export default function ApprovalButton(props: OfferProps) {
  const [open, setOpen] = useState(false);

  if (props.approved) {
    return null;
  }

  return (
    <>
      <div className={styles.actionButtonContainer}>
        <OfferButton
          label={"לאישור דיגיטלי של הצעת המחיר לחץ כאן"}
          onClick={() => {
            setOpen(true);
            reportEvent("Approve Offer");
          }}
        />
      </div>

      <div className={styles.manualApproval}>
        <br />
        <div className={styles.title}>אישור הזמנת הרצאה</div>
        הרני לאשר את הזמנה מספר
        {` ${props.offerId}`}, בתנאים המפורטים בהצעת המחיר הנ״ל.
        <div className={styles.approvalFields}>
          <ApprovalField label="שם ומשפחה" />
          <ApprovalField label="תפקיד" />
          <ApprovalField label="שם הארגון" />
          <ApprovalField label="תאריך הרצאה" />
          <ApprovalField label="חתימה" />
        </div>
      </div>

      <ApprovalDialog
        open={open}
        closeDialog={() => setOpen(false)}
        offerId={props.offerId}
        onApproved={props.onApproved}
      />
    </>
  );
}

function ApprovalField(props: { label: string }) {
  return <div className={styles.approvalField}>{props.label}</div>;
}
