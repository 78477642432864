import { useLocation } from "react-router";
import Offer from "./Offer";
import { OfferApprovalDetails, OfferDataResponse } from "@violet/common";
import { useEffect, useState } from "react";
import PageNotFound from "../components/PageNotFound";
import * as OfferFunctions from "./OfferFunctions";
import LoadingScreen from "./LoadingScreen";

interface UrlParameters {
  offerId: string;
  lectureId: string;
  token: string;
}

enum FetchStatus {
  Fetching,
  Fetched,
  Failed,
}

export default function OfferContainer() {
  const location = useLocation();
  const { offerId, lectureId, token } = parseUrlParams(location.search);

  const [offerData, setOfferData] = useState<OfferDataResponse | undefined>();
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.Fetching);

  useEffect(() => {
    setFetchStatus(FetchStatus.Fetching);
    if (!offerId || !lectureId || !token) {
      setFetchStatus(FetchStatus.Failed);
      return;
    }

    OfferFunctions.getOfferDetails(offerId, lectureId, token)
      .then((res) => {
        setOfferData(res.data);
        setFetchStatus(FetchStatus.Fetched);
      })
      .catch(() => setFetchStatus(FetchStatus.Failed));
  }, [offerId, lectureId, token, setFetchStatus]);

  const markAsApproved = (approvalDetails: OfferApprovalDetails) => {
    if (!offerData) return;

    setOfferData({
      ...offerData,
      approvingContactName:
        approvalDetails.approvingUserFirstName +
        " " +
        approvalDetails.approvingUserLastName,
      approvalDate: approvalDetails.approvalTime,
    });
  };

  switch (fetchStatus) {
    case FetchStatus.Fetching:
      return <LoadingScreen />;

    case FetchStatus.Fetched:
      return (
        <Offer
          offerData={offerData!}
          onApproved={(approvalDetails: OfferApprovalDetails) =>
            onApproved(offerId, lectureId, token, approvalDetails).then(() =>
              markAsApproved(approvalDetails)
            )
          }
        />
      );

    case FetchStatus.Failed:
      return <PageNotFound />;
  }
}

async function onApproved(
  offerId: string,
  lectureId: string,
  token: string,
  approvalDetails: OfferApprovalDetails
): Promise<any> {
  return OfferFunctions.approveOffer(
    offerId,
    lectureId,
    token,
    approvalDetails
  );
}

function parseUrlParams(search: string): UrlParameters {
  const rawParams = search.replace("?", "").split("&");
  const extractedParams: { [key: string]: string } = {};
  rawParams.forEach((item) => {
    const itemPair = item.split("=");
    extractedParams[itemPair[0]] = itemPair[1];
  });

  return {
    offerId: extractedParams.o,
    lectureId: extractedParams.l,
    token: extractedParams.t,
  };
}
