import axios from "axios";
import { ApproveOfferRequest, OfferApprovalDetails } from "@violet/common";

const functionBaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://us-central1-violet-36bed.cloudfunctions.net"
    : "https://us-central1-violet-dev.cloudfunctions.net";

export function getOfferDetails(
  offerId: string,
  lectureId: string,
  token: string
) {
  return axios.get(
    `${functionBaseUrl}/offerData?offerId=${offerId}&lectureId=${lectureId}&token=${token}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
      },
    }
  );
}

export function approveOffer(
  offerId: string,
  lectureId: string,
  token: string,
  details: OfferApprovalDetails
) {
  const request: ApproveOfferRequest = {
    ...details,
    offerId,
    lectureId,
    token,
  };

  return axios.get(
    `${functionBaseUrl}/approveOffer?${objectToUrlParams(request)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
      },
    }
  );
}

function objectToUrlParams(obj: any) {
  let str = "";
  for (let key in obj) {
    if (str) {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str;
}
