import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OfferContainer from "./offer/OfferContainer";
import PageNotFound from "./components/PageNotFound";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path={"/v1"} element={<OfferContainer />} />
        <Route path={"*"} element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
